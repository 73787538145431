import React, { useState } from 'react';
import { Container } from '../components';
import { postNewsletterEntry } from '../services/contact';

const SubscribeModule = (props) => {
    const { title, name } = props;
    const [busy, setBusy] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const onSubmit = async (evt) => {
        setBusy(true);

        let obj = {};
        let isValid = true;
        const domInput = evt.target.elements.email; //document.getElementById(f);
        const valid = domInput.checkValidity();
        domInput.classList.remove('invalid');
        const value = domInput.value;
        if (!valid) {
            isValid = false;
            domInput.classList.add('invalid');
        }
        obj['email'] = value;

        if (!isValid) {
            setBusy(false);
            return;
        }

        try {
            const response = await postNewsletterEntry(obj);
            if (response.status == 200) {
                setSubmitSuccess(true);
            } else {
                console.log("SubscribeModule:", response);
                alert('An error occured submitting your email');
            }
        } catch (err) {
            console.log("SubscribeModule:", err);
            alert('An error occured submitting your email');
        }

        setBusy(false);
    }

    return (
        <Container wrapperClassName={name}>
            <div className="title">{title}</div>
            <form onSubmit={onSubmit} method="post">
                <input placeholder="yourname@email.com.au" id="email" type="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" required />
                {!submitSuccess && 
                    <button className="btn-opacity submit" disabled={busy}>
                        Lets do this
                    </button>
                }
                {submitSuccess && 
                    <button className="btn-opacity submit" disabled>
                        All done
                    </button>
                }
            </form>
        </Container>
    );
};

export default SubscribeModule;