import React, {Component} from 'react';
import {Container} from '../components';
import Link from 'next/link';
import {Image} from '../components/image';

export default class OurClientsModule extends Component {
    render() {
        const {title, clients} = this.props;
        return (
            <Container wrapperClassName="bg-white-text-black-h3 content-centered our-clients">
                {title && <div className="title">{title}</div>}
                <div className="clients-grid">
                    {clients?.map((client, index) => (
                        <div className="client-logo" key={index}>
                            <div className="client-logo-inner">
                                {client.link ? (
                                    <Link href={client.link ? client.link : '#'} target="_blank" rel="noopener noreferrer">
                                        <Image alt="" src={client.logo} />
                                    </Link>
                                ) : (
                                    <Image alt="" src={client.logo} />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        );
    }
}